import React, { useState, useMemo, useCallback } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import { useHistory } from "react-router-dom";
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { tomarLLamada, obtenerListaTurnos, refreshSesion } from '../services/api.js';
import { Table } from "react-materialize";
import { TableHeader, Pagination } from "../components/DataTable";
import Spinner from '../components/Spinner';

let intervalo;

const Home = () => {


  const history = useHistory();
  const [tiempo, setTiempo] = useState('');

  const [dataAgentes, setdataAgentes] = useState([])
  const [loading, setLoading] = useState(false);

  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 12;
  const headers = [
    { name: "Cliente", field: "cliente", sortable: false },
    { name: "Otorgante", field: "otorgante", sortable: false },
    { name: "No. de espera", field: "espera", sortable: false },
    { name: "Estatus", field: "status", sortable: false }
  ];
  const [consultarTurnos, setConsultarTurnos] = useState(true);

  const dataListaAgentes = useMemo(() => {
    let computedComments = dataAgentes;
    setTotalItems(computedComments.length);
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [dataAgentes, currentPage, sorting]);

  useEffect(() => {

    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    console.log("interval_id:",interval_id);
    for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
    }
    let user = localStorage.getItem("username")
    if (user === "") {
      history.push('/index');
    }
    let to = localStorage.getItem("token")
    if (to === "") {
      history.push('/index');
    }
    let time = localStorage.getItem("tiempo");
    if (time !== null) {
      setTiempo(time)
    }
    materializeStylos();
    obtenerListaAgentes();
    window.scrollTo(0, 0);
    //handleClickSendMessage();
  }, [])

  useEffect(() => {
    try {
      intervalo = setInterval(async () => {
        await obtenerListaAgentes();
      }, 4000);
    } catch (e) {
      console.log("Error: " + e);
    }
  }, []);


  useEffect(() => {
    localStorage.setItem("listaAgentes", JSON.stringify(dataAgentes));
    setdataAgentes(dataAgentes);
  }, [dataAgentes])

  const materializeStylos = () => {
    var elems = document.querySelectorAll('.tooltipped');
    var instances = M.Tooltip.init(elems, []);
  }

  const obtenerListaAgentes = async () => {
    //console.log("obtener lista");
    setLoading(true);
    let statusTurno = 'EN_ESPERA';
    obtenerListaTurnos(statusTurno).then((res) => {
      if (res.status === 200) {
        let data = res.data.payload;
        setdataAgentes(data);
      }
    }).catch((err) => {
      setLoading(false);
      console.log("algun error:", err);
      cerrar();
    }).finally(() => {
      setTimeout(() => {
        setLoading(false)
      }, 500);
  })
}


  const cerrar = () => {
    M.toast({ html: 'Tiempo de sesión terminado' });
    clearInterval(intervalo);
    setTimeout(() => {
      history.push('/index');
    }, 200);
  }

  const tomarVideo = async () => {
    let rtoken = localStorage.getItem("token_refresh");
    refreshToken(rtoken);
    tomarLLamada().then(({status, data:{payload}}) => {
      if (status === 200) {
        if ((payload).length > 0) {
          localStorage.setItem("id_call", payload[0].id)
          localStorage.setItem("link_call", payload[0].clienteVideoLink)
          localStorage.setItem("clienteUuid", payload[0].clienteUuid);
          localStorage.setItem("nombreCliente", payload[0].nombreCompleto);
          localStorage.setItem("telCliente", payload[0].telefono);
          localStorage.setItem("clienteTrx", payload[0].clienteTransaccion);
          localStorage.setItem("otorganteUuid", payload[0].idOtorgante.id);
          clearInterval(intervalo);
          setTimeout(() => {
            history.push('/video_llamada');
          }, 200);
        } else {
          M.toast({ html: 'No hay turnos en cola' });
        }
      }
    }).catch((err) => {
      console.error(err);
      console.log("algun error" + err);
      M.toast({ html: 'error:' + err });
    })
  }

  //const handleReset = () => reset()

  const refreshToken = (token) => {
    refreshSesion(token).then((response) => {
      if (response.status === 200) {
        console.log("Se refrescó el token");
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("token_refresh", response.data.refresh_token);
        //handleReset();//reinicia remaining y elapsed
      }
    }).catch(() => {
      console.log("algun error");
      M.toast({ html: 'Se termino tiempo de sesión' });
      setTimeout(() => {
        history.push('/index');
      }, 200);
    })
  }

  return (
    <div>
      <HeaderPage></HeaderPage>
      <div className="row">

        <br /><br />
        <div className="col s12 m11 z-depth-3 mzauto extra_pdd">

          <div className="col s12 jumbotron clear_float">
            <h4>Sala de espera <small>| Video Chat</small></h4>
            <p className="lead">Ve el listado de clientes en espera de ser atendidos.</p>
            <hr className="my-4" />
            <a className="waves-effect waves-light btn red accent-4 forcewidth100" type="button" name="action" onClick={e => tomarVideo(e.target.id)}>TOMAR VIDEO LLAMADA</a>
          </div>
          <br></br>
          <Table>
            <TableHeader
              headers={headers}
              onSorting={(field, order) =>
                setSorting({ field, order })
              }
            />
            <tbody>
              {dataListaAgentes.map((item, index) => (
                <tr key={item.id}>
                  <td>{(item.clienteUuid).split("-")[0]}</td>
                  <td>{item.idOtorgante.nombre}</td>
                  <td>{item.lugar}</td>
                  <td><a href="#!" ><span className="new badge gray">{item.idStatusTurno.id.replace("_", " ")}</span></a></td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br></br>

          <div className="row">
            <div className="col s12 ">
              <div className="centrarDiv">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>

          </div>

        </div>

        {/* <div className="fixed-action-btn">
          <a className="btn-floating btn-large blue darken-3 tooltipped" onClick={e => obtenerListaAgentes()} data-position="left" data-tooltip="Actualizar lista">
            <i className="large material-icons">autorenew</i>
          </a>
        </div> */}

      </div>

      <Spinner state={loading}></Spinner>

      <LogoutPopup></LogoutPopup>
    </div>
  )
}

export default Home