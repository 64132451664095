import React, { useEffect, useState, useMemo } from "react";
import M from 'materialize-css';
import {Table, Badge, Select} from 'react-materialize';
import { TableHeader, Pagination, Search } from "../components/DataTable";
import { useHistory } from "react-router-dom";
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import Spinner from '../components/Spinner';
import { obtenerReporte, obtenerReporteFechas, obtenerInfoTurnos } from '../services/api.js';

const Reportes = () => {

    const history = useHistory();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    let [currentO, setcurrentO] = useState("");
    const [comments, setComments] = useState([]);
    const ITEMS_PER_PAGE = 12;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [initDate, setInitDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [listaOtorg, setListaOtorg] = useState("");

    const headers = [
        // { name: "Agente", field: "NOMBRE_SECCION", sortable: false },
        { name: "Agente", field: "AGENTE", sortable: false },

        // { name: "Contacto", field: "CONTACTO", sortable: false },
        { name: "ICU", field: "ICU", sortable: false },
        { name: "Teléfono", field: "TELEFONO", sortable: false },
        { name: "Fecha de solicitud", field: "FECHA_Y_HORA_DE_SOLICITUD", sortable: false },
        { name: "Tiempo de espera", field: "TIEMPO_DE_ESPERA", sortable: false },
        // { name: "Fecha y hora de inicio", field: "FECHA_Y_HORA_INICIO", sortable: false },
        // { name: "Fecha y hora de fin", field: "FECHA_Y_HORA_FIN", sortable: false },
        { name: "Duración", field: "DURACION", sortable: false },
        { name: "Interacción ID", field: "INTERACTION_ID", sortable: false },
        // { name: "Comentarios", field: "COMENTARIOS", sortable: false },
        { name: "Motivo", field: "MOTIVO", sortable: false },
        // { name: "Submotivo", field: "SUBMOTIVO", sortable: false },
        { name: "ID Salesforce", field: "ID_SALESFORCE", sortable: false },
    ];

    useEffect(() => {
        let user = localStorage.getItem("username")
        if (user === "") {
                history.push('/index');
        }
        let to = localStorage.getItem("token")
        if (to === "") {
                history.push('/index');
        }
        obtenerOtorgantes();
        materializeStylos();
    }, [])

    const commentsData = useMemo(() => {
        // console.log("currentPage",currentPage);
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(
                comment => comment.COMENTARIOS.includes(search.toLowerCase())
                || comment.AGENTE.toLowerCase().includes(search.toLowerCase())
                || comment.TELEFONO.toLowerCase().includes(search.toLowerCase())
                || comment.MOTIVO.includes(search.toLowerCase())
            );
        }

        // console.log("computedComments",computedComments.length);
        setTotalItems(computedComments.length);
        // return computedComments.slice(
        //     (currentPage - 1) * ITEMS_PER_PAGE,
        //     (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        // );
        return computedComments;
    }, [comments, currentPage, search]);

    const limpiarDatos = () => {
        document.getElementById("formFechas").reset();
        cambiarPagina(1);
    }

    const obtenerOtorgantes = () => {
        let lista = localStorage.getItem("dataOtorgante")
            if (lista !== null) {
                    let options = []
                    let final = JSON.parse(lista);
                    for(var i = 0; i < final.length; i++) {
                        var obj = final[i];
                        let busqueda = obtenerValorConfig(obj.otorganteParams, "ENABLE_VIDEOCALL","false")
                        if(busqueda === "true"){
                            var nuevo = new Object();
                            nuevo.id = obj.id;
                            nuevo.nombre = obj.nombre;
                            options.push(nuevo)
                        }
                        
                    }
                    setList(options)
            }else{
                    history.push('/index');
                }
    }

    const obtenerValorConfig = (lista, elemento, valorPredeterminado) => {
        const elementoEncontrado = lista.find(function (item) {
            return item.idCampo === elemento
        })
        if (elementoEncontrado) {
            return elementoEncontrado.valor
        }
    
        return valorPredeterminado
    }

    const materializeStylos = () => {
        var elemDP = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemDP, { container: 'body', format:"yyyy-mm-dd",  maxDate: new Date(), onSelect: function(date) {}});
    }
    
    const busquedaFechas = () => {
        var init = document.getElementById("desde").value;
        var end = document.getElementById("hasta").value;
        if(currentO !== "" ){
            if(init === "" && end === "" ){
                console.log("sin fechas",currentO);
                setLoading(true)
                obtenerReporte(currentO).then((response) => {
                    if (response.status === 200) {
                        // console.log("Obtener reporte",response);
                        if(response.data.payload[0].link){
                            let url = response.data.payload[0].link
                            let win = document.createElement('a');
                            win.setAttribute('target', '_blank');
                            win.href = url;
                            document.body.appendChild(win);
                            win.click();
                            document.body.removeChild(win);
                        }else{
                            M.toast({html: 'No hay reportes con la información ingresada'});
                        }
                    }
                    }).catch((error) => {
                        console.log("algun error"+error);
                        M.toast({html: 'No se actualizo el score'});
                        if(error.includes("401")){
                            M.toast({html: 'Tiempo de sesión terminado'});
                            setTimeout(() => {
                                history.push('/index');
                            }, 200);
                        }
                    }).finally(() => {
                        setLoading(false)
                    })
            }else{
            console.log("con fechas");
            if(init !== "" && end !== "" ){
                let fecha = new Date();
                let init2 = new Date (init);
                let end2 = new Date (end);
                let time1 = init2.getTime()
                let time2 = end2.getTime()
                let listaO = "";
                setLoading(true);
                setInitDate(time1);
                setEndDate(time2);
                            
                obtenerReporteFechas(currentO, time1,time2, listaO).then((response) => {
                if (response.status === 200) {
                    // console.log("response",response);
                        if(response.data.payload[0].link){
                            let url = response.data.payload[0].link
                            let win = document.createElement('a');
                            win.setAttribute('target', '_blank');
                            win.href = url;
                            document.body.appendChild(win);
                            win.click();
                            document.body.removeChild(win);
                        }else{
                            M.toast({html: 'No hay reportes con la información ingresada'});
                        }
                }
                }).catch((error) => {
                    console.log("algun error"+error);
                    M.toast({html: 'No se actualizo el score'});
                    if(error.includes("401")){
                        M.toast({html: 'Tiempo de sesión terminado'});
                        setTimeout(() => {
                            history.push('/index');
                        }, 200);
                    }
                }).finally(() => {
                    setLoading(false)
                })
            }else{
                M.toast({html: 'Selecciona un rango de fechas'});
            }
        }
        }else{
            M.toast({html: 'No se selecciono otorgante'});
        }
    }

    const cargarSelect = (id) => {
        console.log("Entro la funcion");
        setcurrentO(id)
        setLoading(true)
        document.getElementById("formFechas").reset();
        resultadosTabla(id);
    }

    const resultadosTabla = async (id) => {
        obtenerInfoTurnos(id,1, ITEMS_PER_PAGE).then((response) => {
            if (response.status === 200) {
                //console.log("response",response);
                if (response.data.payload.items.list.length > 0) {
                    let json = response.data.payload.items.list;
                    // console.log("json",json);
                    setComments(json);
                    setTotalItems(response.data.payload.items.totalElements)
                    //M.toast({html: 'Datos actualizados'});
                    
                }else{
                    setComments([])
                    M.toast({html: 'No hay datos'});
                }
            }
        }).catch((error) => {
            console.log("algun error"+error);
            M.toast({html: 'No se actualizo plantilla'});
            if(error.includes("401")){
                M.toast({html: 'Tiempo de sesión terminado'});
                setTimeout(() => {
                    history.push('/index');
                }, 200);
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    
    const cambiarPagina = async (page) => {
        let init = initDate;
        let end = endDate;
        setLoading(true)
        if(currentO !== "" ){
            if(init === "" && end === "" ){
                console.log("sin fechas",currentO);
                obtenerInfoTurnos(currentO,page, ITEMS_PER_PAGE).then((response) => {
                    if (response.status === 200) {
                        // console.log("response",response);
                        if (response.data.payload.items.list) {
                            if (response.data.payload.items.list.length > 0) {
                                let json = response.data.payload.items.list;
                                // console.log("json",json);
                                setComments(json);
                                setTotalItems(response.data.payload.items.totalElements)
                                //M.toast({html: 'Datos actualizados para nueva página'});
                            }else{
                                setComments([])
                            }
                        }else{
                            setComments([])
                        }
                    }
                }).catch((error) => {
                    console.log("algun error"+error);
                    M.toast({html: 'No se actualizo plantilla'});
                    if(error.includes("401")){
                        M.toast({html: 'Tiempo de sesión terminado'});
                        setTimeout(() => {
                            history.push('/index');
                        }, 200);
                    }
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                console.log("con fechas");
                if(init !== "" && end !== "" ){
                    let init = initDate;
                    let end = endDate;
                                
                    obtenerInfoTurnos(currentO,page, ITEMS_PER_PAGE, init, end).then((response) => {
                        if (response.status === 200) {
                            // console.log("response",response);
                            if (response.data.payload.items.list) {
                                if (response.data.payload.items.list.length > 0) {
                                    let json = response.data.payload.items.list;
                                    // console.log("json",json);
                                    setComments(json);
                                    setTotalItems(response.data.payload.items.totalElements)
                                    //M.toast({html: 'Datos actualizados para nueva página'});
                                }else{
                                    setComments([])
                                }
                            }else{
                                setComments([])
                            }
                        }
                    }).catch((error) => {
                        console.log("algun error"+error);
                        M.toast({html: 'No se actualizo plantilla'});
                        if(error.includes("401")){
                            M.toast({html: 'Tiempo de sesión terminado'});
                            setTimeout(() => {
                                history.push('/index');
                            }, 200);
                        }
                    }).finally(() => {
                        setLoading(false)
                    })
                }else{
                    M.toast({html: 'Selecciona un rango de fechas'});
                }
            }
        }
    }

    const mostrarFechas = async () => {
        var init = document.getElementById("desde").value;
        var end = document.getElementById("hasta").value;
        if(currentO !== "" ){
            if(init !== "" && end !== "" ){
                setLoading(true)
                let init2 = new Date (init);
                let end2 = new Date (end);
                let time1 = init2.getTime()
                let time2 = end2.getTime();
                setInitDate(time1);
                setEndDate(time2);

                if (end2 < init2) {
                    M.toast({ html: 'La fecha de inicio no puede ser mayor a la fecha de fin' });
                    setLoading(false);
                    // cleanSearch();
                    limpiarDatos();
                } else {
                    obtenerInfoTurnos(currentO,1, ITEMS_PER_PAGE, time1, time2).then((response) => {
                        if (response.status === 200) {
                            //console.log("response",response);
                            if (response.data.payload.items.list) {
                                if (response.data.payload.items.list.length > 0) {
                                let json = response.data.payload.items.list;
                                //console.log("json",json);
                                setComments(json);
                                setTotalItems(response.data.payload.items.totalElements)
                                setCurrentPage(1)
                                //M.toast({html: 'Datos actualizados para nueva página'});
                                }else{
                                    setComments([])
                                    M.toast({html: 'No hay datos en ese rango de fechas'});
                                }
                            }else{
                                setComments([])
                                M.toast({html: 'No hay datos en ese rango de fechas'});
                            }
                        }
                    }).catch((error) => {
                        console.log("algun error"+error);
                        M.toast({html: 'No se actualizo plantilla'});
                        if(error.includes("401")){
                            M.toast({html: 'Tiempo de sesión terminado'});
                            setTimeout(() => {
                                history.push('/index');
                            }, 200);
                        }
                    }).finally(() => {
                        setLoading(false)
                    }) 
                }
            }else{
                M.toast({html: 'Selecciona un rango de fechas'});
            }
        }else{
            M.toast({html: 'No se selecciono otorgante'});
        }
    }

    // const cleanSearch = () => {
    //     document.getElementById("hasta").value='';
    //     document.getElementById("desde").value='';
    //     document.getElementById("formFechas").reset();
    //     cambiarPagina(1);
    // }

    return (
        <div>
            <HeaderPage></HeaderPage>
            <div className="row">
            <br />
            <div className="col s12 m11 z-depth-3 mzauto extra_pdd">
            <div className="col s12 jumbotron clear_float">
                    <h4>Reporte Videollamadas</h4>
                    <p className="lead">Descarga el listado de llamadas y consulta sus detalles.</p>
                    <hr className="my-4" />
                </div>
                <br></br>
                <div className="row">
                        <div className="col s12 m6 select">
                            <Select
                                    id="Select-9"
                                    multiple={false}
                                    onChange={(e) => cargarSelect(e.target.value)}
                                    options={{
                                        classes: 'forcewidth100',
                                        dropdownOptions: {
                                        alignment: 'left',
                                        autoTrigger: true,
                                        closeOnClick: true,
                                        constrainWidth: true,
                                        coverTrigger: true,
                                        hover: false,
                                        inDuration: 150,
                                        onCloseEnd: null,
                                        onCloseStart: null,
                                        onOpenEnd: null,
                                        onOpenStart: null,
                                        outDuration: 250
                                        }
                                    }}
                                    value=""
                                    >
                                    <option
                                        disabled
                                        value=""
                                    >
                                        Selecciona un otorgante
                                    </option>
                                    {list.map((item, index) => <option key ={`${item.index}`} value={`${item.id}`}>{item.nombre}</option>)}
                                </Select>
                        </div>
                        <div className="col s12 m6"></div>

                        <div className="col s12 ">
                            <form id="formFechas" method="post" action="">
                                <div className="col m1"></div>
                                <div className="input-field col m5 nomarg_n_pad_left">
                                    <input id="desde" type="text" className="datepicker" defaultValue=""/>
                                    <label htmlFor="desde" className="">Filtrar desde</label>
                                </div>
                                <div className="input-field col m5 nomarg_n_pad">
                                    <input id="hasta" type="text" className="datepicker" defaultValue=""/>
                                    <label htmlFor="hasta" className="">Filtrar hasta</label>
                                </div>
                                <button type="button" className="waves-effect btn-flat col m1 l1 topMargin" onClick={e => mostrarFechas()}> <i className="material-icons prefix">search</i></button>

                            </form>
                        </div>
                        {/* <div className="col m12">
                            <button type="button" className="waves-effect btn-flat col m1 l1 topMargin" onClick={e => mostrarFechas()}> <i className="material-icons prefix">search</i></button>
                            <div className="input-field col m11 l11">
                                <Search
                                    onSearch={value => {
                                        setSearch(value);
                                        setCurrentPage(1);
                                    }}
                                />
                            </div>
                        </div> */}
                        <div className="col s12 m12">
                            <div className="col m6"></div>
                            <div className="col m3 input-field">
                                <button type="button" onClick={e => limpiarDatos()} className="btn btn-raised btn-primary contentBtn">Limpiar filtros</button>
                            </div>
                            <div className="col m3 input-field">
                                <button type="button" onClick={e => busquedaFechas()} className="btn btn-raised btn-primary contentBtn grey">Descargar reportes</button>
                            </div>
                        </div>
                        <div className="col m12">
                            <Table className="responsive-table striped">
                                <TableHeader
                                    headers={headers}
                                    onSorting={(field, order) =>
                                        setSorting({ field, order })
                                    }
                                    />
                                    <tbody>
                                            {
                                                commentsData.map((comment, index) => (
                                                <tr key={"row-" + index}>
                                                    <td>{comment.AGENTE}</td>
                                                    {/* <td>{comment.NOMBRE_SECCION}</td> */}
                                                    {/* <td>{comment.CONTACTO}</td> */}
                                                    <td>{comment.ICU}</td>
                                                    <td>{comment.TELEFONO}</td>
                                                    <td>{comment.FECHA_Y_HORA_DE_SOLICITUD}</td>
                                                    <td>{comment.TIEMPO_DE_ESPERA}</td>
                                                    {/* <td>{comment.FECHA_Y_HORA_INICIO}</td>
                                                    <td>{comment.FECHA_Y_HORA_FIN}</td> */}
                                                    <td>{comment.DURACION}</td>
                                                    <td>{comment.INTERACTION_ID}</td>
                                                    {/* <td>{comment.COMENTARIOS}</td> */}
                                                    <td>{comment.MOTIVO}</td>
                                                    {/* <td>{comment.SUBMOTIVO}</td> */}
                                                    <td>{comment.ID_SALESFORCE}</td>
                                                    {/* <td><a href="#modalEditOts" class="modal-trigger"><span class="new badge hoverable">DETALLE</span></a></td> */}
                                                </tr>
                                            )) 
                                            }
                                    </tbody>
                            </Table>
                            <br></br>
                            <div className="row">
                                <div className="col s12 ">
                                    <div className="centrarDiv">
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={page => {setCurrentPage(page); cambiarPagina(page);}}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <Spinner state={loading}></Spinner>
        <LogoutPopup></LogoutPopup>
        </div>
    )
}



export default Reportes
