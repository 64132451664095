import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './pages/home'
import Index from './pages/index'
import VideoLlamada from './pages/video_llamada'
import Base from './pages/base'
import Reportes from './pages/reportes'
import Restablecer from './pages/restablecer';
import Seguimientos from './pages/seguimientos';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/index">
          <Index />
        </Route>
        <Route path="/video_llamada">
          <VideoLlamada />
        </Route>
        <Route path="/base">
          <Base />
        </Route>
        <Route path="/reportes">
          <Reportes />
        </Route>
        <Route path="/seguimientos">
          <Seguimientos />
        </Route>
        <Route path="/check">
          <Restablecer />
        </Route>
        <Route path="/">
          <Index />
        </Route>
        </Switch>
    </Router>
  )}

export default App;
